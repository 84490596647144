import React, { useEffect, useState, useContext } from 'react';
import router from 'next/router';

import { UserBadgeContext } from 'context/BadgesContext';
import User, { BadgesInfo } from 'src/proxyClient/users';
import { getSession } from 'next-auth/client';
import { getUserId } from 'src/common/authUtil';
import Drawing from 'src/apiClient/drawing';

import Link from 'next/link';
import LetterAvatar from 'components/LetterAvatar';
import { isMobile } from 'react-device-detect';

import styles from 'styles/BodyHeader.module.scss';
import Button from './Button';

function formatHowManyNotifications(notifications: number): number {
  return Math.min(notifications, 9);
}

function countHowManyNotifications(arrayOfUserBadges: BadgesInfo[]): number {
  return arrayOfUserBadges.filter((item) => item.seen === false).length;
}

export interface BodyHeaderProps {
  mobileBanner?: Boolean;
}

const BodyHeader: React.FC<BodyHeaderProps> = ({
  mobileBanner = true,
}) => {
  const [username, setUsername] = useState('usuário');
  const [numberOfTrophies, setNumberOfTrophies] = useState(0);
  const [numberOfNotifications, setNumberOfNotifications] = useState(0);
  const [featuredDrawing, setFeaturedDrawing] = useState(null);

  const { setUserBadges, setDateMostRecentlyAquired } = useContext(
    UserBadgeContext,
  );

  useEffect(() => {
    async function readUsername() {
      const session = await getSession();
      if (session?.user?.name) {
        setUsername(session?.user?.name);
      }
    }
    readUsername();
  }, []);

  const returnImgAndNotificationComponentCorrectly = (
    notifications: number,
    trophies: number,
  ) => {
    if (notifications === 0) {
      return (
        <img
          src="/assets/feedbackTrophy.svg"
          alt={trophies === 1 ? `${trophies} Moedas` : `${trophies} Moeda`}
        />
      );
    }
    return (
      <>
        <img
          src="/assets/feedbackTrophy.svg"
          alt={trophies === 1 ? `${trophies} Moedas` : `${trophies} Moeda`}
        />
        <span
          data-cy="notificationComponent"
          className={styles.notificationComponent}
        >
          {formatHowManyNotifications(notifications)}
        </span>
      </>
    );
  };

  useEffect(() => {
    const fetchFeaturedDrawings = async () => {
      const session = await getSession();
      const activeDrawings = await Drawing.getDrawings(
        getUserId(session),
        true,
      );

      if (activeDrawings.length < 1) return;

      const featuredDrawingObj = await Drawing.getDrawingById(
        activeDrawings[0].id,
      );

      if (featuredDrawingObj?.result === null) {
        setFeaturedDrawing(featuredDrawingObj);
      }
    };

    function updateNumberOfTrophiesAndNotifications(response: BadgesInfo[]) {
      const notificationQuantity = countHowManyNotifications(response);
      setNumberOfNotifications(notificationQuantity);
      setNumberOfTrophies(response.length);
      setUserBadges(
        response.map((item) => ({
          id: item.id,
          seen: item.seen,
          dateOfAcquisition: item.dateOfAcquisition,
        })),
      );
      setDateMostRecentlyAquired(response[0]?.dateOfAcquisition ?? null);
    }

    async function getUserBadgesAndUpdateData() {
      try {
        const session = await getSession();
        const userBadgesResult = await User.getUserBadges(getUserId(session));
        updateNumberOfTrophiesAndNotifications(userBadgesResult);
      } catch (err) {
        updateNumberOfTrophiesAndNotifications([]);
      }
    }
    getUserBadgesAndUpdateData();
    fetchFeaturedDrawings();
  }, []);

  const getLandingImage = () => (isMobile && featuredDrawing?.hasMobileLandingImage
    ? `${featuredDrawing?.landingImage.split('.')[0]}-mobile.${featuredDrawing?.landingImage.split('.')[1]}`
    : featuredDrawing?.landingImage);

  return (
    <article className={styles.gridClassHeader}>
      <div className={`${styles.flexClassWrapper} ${!mobileBanner ? `${styles['-noMobileBanner']}` : ''}`}>
        <Link href="editar-perfil">
          <div className={styles.profilePicture} data-cy="ProfilePicture">
            <LetterAvatar name={username} />
          </div>
        </Link>

        <div className={styles.headerBar} data-cy="ProfileInfo">
          <span>
            Olá{' '}
            <strong>
              <Link href="/editar-perfil">
                {username.toUpperCase()}
              </Link>
              !
            </strong>
          </span>
          <Link
            href={numberOfTrophies === 0 ? '/sobre-trofeus' : '/meus-trofeus'}
          >
            <div className={styles.trophyArea}>
              <div className={styles.trophyImgArea}>
                {numberOfTrophies === 0 ? (
                  <img
                    src="/assets/green-coin-header.svg"
                    alt={`${numberOfTrophies} Moedas`}
                  />
                ) : (
                  returnImgAndNotificationComponentCorrectly(
                    numberOfNotifications,
                    numberOfTrophies,
                  )
                )}
              </div>
              <span data-cy="trophyQuantity">
                {numberOfTrophies !== 1
                  ? `${numberOfTrophies} Moedas`
                  : `${numberOfTrophies} Moeda`}
              </span>
            </div>
          </Link>
        </div>
        <div className={styles.seeTrophyContainer}>
          <Button
            className={styles.seeTrophyButton}
            color="white-border-blue"
            onClick={() => {
              router.push('/sobre-trofeus');
            }}
            type="button"
          >
            <img src="/assets/coin.svg" alt="Moeda" /> Como obter moedas
          </Button>
        </div>
      </div>
      {featuredDrawing && (
        <div
          className={`${styles.bannerPromo} ${!mobileBanner ? `${styles['-noMobileBanner']}` : ''}`}
        >
          <img
            className={styles.bannerImage}
            src={getLandingImage()}
            alt="Banner Promoção"
          />
          <Button
            className={styles.button}
            onClick={() => {
              window.open(featuredDrawing?.landingUrl, '_blank').focus();
            }}
            type="button"
          >
            Quero saber mais
          </Button>
        </div>
      )}
    </article>
  );
};

export default BodyHeader;
