import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

import Utils from 'src/infra/utils';

interface Props {
  name: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      fontSize: '1.5rem',
      fontWeight: '700',
      width: theme.spacing(10),
      height: theme.spacing(10),
      [theme.breakpoints.up('md')]: {
        width: theme.spacing(11.5),
        height: theme.spacing(11.5),
      },
    },
  },
  green: {
    backgroundColor: 'var(--background-light-pink)',
    marginRight: '1rem',
  },
}));

const LetterAvatar: React.FC<Props> = ({ name }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Avatar className={classes.green}>{Utils.getInitials(name)}</Avatar>
    </div>
  );
};

export default LetterAvatar;
