import client from './client';
import { UserProfile } from './users';

const DRAWING_ENDPOINT = '/drawing';
const GET_DRAWING_BY_ID_ENDPOINT = (id: number | string): string => `${DRAWING_ENDPOINT}/${id}`;

const GET_DRAWING_INVITE = (id: number | string): string => `${DRAWING_ENDPOINT}/${id}/friend-invite`;

const GET_DRAWING_HAS_QUIZ_ANSWERED = (id: number, userId: string): string => `${DRAWING_ENDPOINT}/${id}/hasQuizTicket/${userId}`;

const SEND_DRAWING_INVITE = (id: number | string): string => `${DRAWING_ENDPOINT}/${id}/friend-invite`;

const SEND_DRAWING_INVITE_BADGE = (): string => `${DRAWING_ENDPOINT}/friend-invite/hash`;

export interface DrawingTicket {
  id: number;
  ticketNumber: string;
  invitedId: string;
  createdAt: string;
  inviterUser: UserProfile;
  invitedEmail?: string;
}

export interface WaitingInvite {
  userId: string,
  drawingId: number,
  invitedEmail: string,
  createdAt: string
}

export interface Drawing {
  id: number;
  title: string;
  description: string;
  inviteFactor: number;
  quizUrl: string;
  bannerImage: string,
  landingImage: string,
  landingUrl: string;
  termsUrl: string;
  rulesUrl: string;
  result: string;
  status: string;
  validThru: string;
  drawingTickets: Array<DrawingTicket>;
  waitingInvites: Array<WaitingInvite>;
  checkDate: string;
  dueDate: string;
  startDate: string;
  prizeDescription?: string;
}

export interface HasTicket {
  hasTicket: boolean
}

export interface SweepstakeTicketsWithUsersResponseDto {
  ticketNumber: number,
  user: {
    name: string,
    email: string,
    cpf: string,
    cellphone: string,
  }
}

const getDrawings = async (
  sessionToken: string,
  active?: boolean,
): Promise<Drawing[]> => {
  try {
    let query = '&';

    if (active !== undefined) {
      query += `active=${active}`;
    }

    const drawings = await client.get<Drawing[]>(`/drawing?user=${sessionToken}${query}`, {
      headers: { Authorization: `Bearer ${sessionToken}` },
    });
    return drawings.data;
  } catch (err) {
    return [];
  }
};

const getDrawingById = async (id: number | string): Promise<Drawing> => {
  try {
    const drawing = await client.get<Drawing>(
      GET_DRAWING_BY_ID_ENDPOINT(id),
    );
    return drawing.data;
  } catch (err) {
    return null;
  }
};

const getDrawingHasQuizAnswered = async (id: number, userId: string): Promise<HasTicket> => {
  try {
    const hasTicket = await client.get<HasTicket>(
      GET_DRAWING_HAS_QUIZ_ANSWERED(id, userId),
    );

    return hasTicket.data;
  } catch (err) {
    return err;
  }
};

const sendDrawingInviteEmail = async (
  drawingId,
  data,
) => {
  try {
    const inviteSent = await client.post<String>(
      SEND_DRAWING_INVITE(drawingId),
      data,
    );

    return inviteSent.status;
  } catch (err) {
    return '';
  }
};

const registerDrawingTicket = async (
  userInviteHash: number | string,
  drawingInviteHash: number | string,
  userId: number | string,
) => {
  try {
    const data = {
      drawingHash: drawingInviteHash,
      invitedHash: userInviteHash,
      userId,
    };

    const inviteSent = await client.post<any>(
      SEND_DRAWING_INVITE_BADGE(),
      data,
    );

    return inviteSent.status;
  } catch (err) {
    return '';
  }
};

const getDrawingInviteByDrawingId = async (
  id: number | string,
): Promise<string> => {
  try {
    const drawingInviteHash = await client.get<any>(GET_DRAWING_INVITE(id));
    return drawingInviteHash.data;
  } catch (err) {
    return '';
  }
};

export default {
  getDrawings,
  getDrawingById,
  getDrawingInviteByDrawingId,
  getDrawingHasQuizAnswered,
  sendDrawingInviteEmail,
  registerDrawingTicket,
};
